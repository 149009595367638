import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const PAGING_SIZE = 30;

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query GetAllImages {
      allFile(sort: {order: DESC, fields: absolutePath}, filter: { sourceInstanceName: { eq: "films" } }) {
        edges {
          node {
            relativePath
            childImageSharp {
              gatsbyImageData(quality: 80, transformOptions: {fit: CONTAIN})
            }
          }
        }
      }
    }
  
  `);
  const images = data.allFile.edges.map((e: any) => e.node);
  const [displaySize, setDisplaySize] = React.useState(PAGING_SIZE);
  function onLoadMore() {
    setDisplaySize(prevSize => prevSize + PAGING_SIZE);
  }
  return (
    <div>
      {/* <Head>
        <title>my little photo exhibition</title>
        <meta name="description" content="little photo exhibition by MicroBenz" />
      </Head> */}

      <main className="container px-4 py-16 mx-auto md:px-0">
        <div className="pb-8">
          <h1 className="text-center">
            someone said to me that i should open my photo exhibition
          </h1>
          <p className="text-center">please enjoy my little photo exhibition :)</p>
        </div>
        <div className="grid items-center grid-cols-1 md:grid-cols-3 gap-x-4 gap-y-4">
          {images.slice(0, displaySize).map((img: any, idx: number) => (
            <div key={img.relativePath}>
              <GatsbyImage
                alt="Image Alt"
                image={getImage(img)}
              />
            </div>
          ))}
        </div>
        {displaySize < images.length && (
          <div className="py-8">
            <a className="block cursor-pointer mx-auto w-fit border-[1px] rounded-lg border-white p-4" onClick={onLoadMore}><p>Load More</p></a>
          </div>
        )}
      </main>
    </div>
  );
}

export default IndexPage
